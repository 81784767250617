import Gallery from "@browniebroke/gatsby-image-gallery";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Quote from "../components/quotes";

export interface IDogodkiProps {}

export function Galerija({ props: IDogodkiProps, data }) {
  const imagesBogracijada = data.bogracijada.edges.map(
    ({ node }) => node.childImageSharp
  );
  const imagesKozel17 = data.kozel2017
    ? data.kozel2017.edges.map(({ node }) => node.childImageSharp)
    : null;
  const imagesKozel18 = data.kozel2018.edges.map(
    ({ node }) => node.childImageSharp
  );
  const imagesKozel19 = data.kozel2019.edges.map(
    ({ node }) => node.childImageSharp
  );

  const sections = [
    { name: "Bogračijada", images: imagesBogracijada },
    { name: "Kozel Fest 2019", images: imagesKozel19 },
    { name: "Kozel Fest 2018", images: imagesKozel18 },
    { name: "Kozel Fest 2017", images: imagesKozel17 },
  ];

  // const selectedEvent = "Bogrcijada";

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Galerija | Gostilna Spirić</title>
        <link rel="canonical" href="https://www.gostilna-spiric.si/galerija" />
        <meta
          name="description"
          content="Organiziramo številne dogodke - v galeriji si lahko ogledate utrinke"
        />
      </Helmet>

      <article className="container flex flex-col px-4 mx-auto mt-8 bg-gray-100 rounded gap-9">
        <div className="flex flex-col items-center gap-5 p-4 md:p-8">
          {/* <LogoSpiric className="col-start-1 row-start-1 self-start justify-self-center max-w-[800px] text-gray-500 opacity-50 fill-current " /> */}
          <div className="">
            <h1 className="content-title">Galerija</h1>
          </div>
          <Quote quote="Včasih je zabava z najboljšimi prijatelji vsa terapija, ki jo potrebuješ." />

          {/* <div className="p-8 overflow-hidden border-2 rounded">
            <h2 className="text-2xl">Kozel Fest 2017</h2>{" "}
          </div> */}

          {sections.map((section) => {
            {
              return (
                section.images && (
                  <section className="flex flex-col pb-4 mb-2 border-b-2">
                    <div className="flex justify-center p-2 mb-6 text-2xl text-center ">
                      <div className="p-2 border-b-2 border-gray-600">
                        {section.name}
                      </div>
                    </div>
                    <Gallery images={section.images} />
                  </section>
                )
              );
            }
          })}

          {/* <section className="flex flex-col pb-4 mb-2 border-b-2">
            <div className="flex justify-center p-2 mb-6 text-2xl text-center ">
              <div className="p-2 border-b-2 border-gray-600">
                Kozel Fest 2018
              </div>
            </div>
            <Gallery images={imagesKozel18} />
          </section> */}
          {/* 
          <section className="flex flex-col pb-4 mb-2 border-b-2">
            <div className="flex justify-center p-2 mb-6 text-2xl text-center ">
              <div className="p-2 border-b-2 border-gray-600">
                Kozel Fest 2019
              </div>
            </div>
            <Gallery images={imagesKozel19} />
          </section>

          <section className="flex flex-col pb-4 mb-2 border-b-2">
            <div className="flex justify-center p-2 mb-6 text-2xl text-center ">
              <div className="p-2 border-b-2 border-gray-600">Bogračijada</div>
            </div>
            <Gallery images={imagesBogracijada} />
          </section> */}
        </div>
      </article>
    </React.Fragment>
  );
}
export const pageQuery = graphql`
  query ImagesForGallery {
    bogracijada: allFile(
      filter: {
        childrenImageSharp: {}
        sourceInstanceName: { eq: "gallery" }
        relativeDirectory: { eq: "Bogracijada" }
        extension: { ne: "heic", nin: "mov" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    kozel2017: allFile(
      filter: {
        childrenImageSharp: {}
        sourceInstanceName: { eq: "gallery" }
        relativeDirectory: { eq: "Kozel fest 2017" }
        extension: { ne: "heic", nin: "mov" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    kozel2018: allFile(
      filter: {
        childrenImageSharp: {}
        sourceInstanceName: { eq: "gallery" }
        relativeDirectory: { eq: "Kozel fest 2018" }
        extension: { ne: "heic", nin: "mov" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }

    kozel2019: allFile(
      filter: {
        childrenImageSharp: {}
        sourceInstanceName: { eq: "gallery" }
        relativeDirectory: { eq: "Kozel fest 2019" }
        extension: { ne: "heic", nin: "mov" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Galerija;
